import React from 'react'
import Plx from 'react-plx'
import Image from 'next/image'
import classNames from 'classnames'

import HomepageAnimation from '@components/blocks/homepage_animation'
import notesAnimationData from '@lottie_animations/homepage_notes.json'
import { withGlobalContext } from '@util/global_context'
import styles from '@components/blocks/homepage_overlay.module.scss'

import phoneEn from '/static/images/illustrations/phone.png'
import phoneEs from '/static/images/illustrations/phone_spanish.png'
import butterfly1 from '/static/images/illustrations/butterfly1.svg'
import butterfly2 from '/static/images/illustrations/butterfly2.svg'
import butterfly3 from '/static/images/illustrations/butterfly3.svg'

// TODO: migrate to next/image, there were positioning issues
const clouds = '/static/images/illustrations/phone_bg.svg'

const plxData = {
  butterfly1: [
    {
      start: 0,
      duration: 26,
      name: 'butterfly1-rot1',
      properties: [
        {
          startValue: 0,
          endValue: 6,
          property: 'rotate',
        },
      ],
    },
    {
      start: 0,
      duration: 34,
      name: 'butterfly1-y1',
      properties: [
        {
          startValue: 0,
          endValue: -22,
          property: 'translateY',
        },
      ],
    },
    {
      start: 26,
      duration: 28,
      name: 'butterfly1-rot2',
      properties: [
        {
          startValue: 6,
          endValue: 0,
          property: 'rotate',
        },
      ],
    },
  ],
  butterfly2: [
    {
      start: 0,
      duration: 43,
      name: 'butterfly2-y1',
      properties: [
        {
          startValue: 0,
          endValue: -49,
          property: 'translateY',
        },
      ],
    },
    {
      start: 16,
      duration: 38,
      name: 'butterfly2-rot1',
      properties: [
        {
          startValue: 0,
          endValue: 13,
          property: 'rotate',
        },
      ],
    },
    {
      start: 54,
      duration: 35,
      name: 'butterfly2-rot2',
      properties: [
        {
          startValue: 13,
          endValue: 0,
          property: 'rotate',
        },
      ],
    },
  ],
  butterfly3: [
    {
      start: 43,
      duration: 115,
      name: 'butterfly3-rot1',
      properties: [
        {
          startValue: 0,
          endValue: -58,
          property: 'rotate',
        },
      ],
    },
    {
      start: 158,
      duration: 118,
      name: 'butterfly3-rot2',
      properties: [
        {
          startValue: -58,
          endValue: -22,
          property: 'rotate',
        },
      ],
    },
    {
      start: 158,
      duration: 338,
      name: 'butterfly3-y1',
      properties: [
        {
          startValue: 0,
          endValue: -90,
          property: 'translateY',
        },
      ],
    },
  ],
  phone: [
    {
      start: 34,
      duration: 757,
      name: 'phone-y1',
      properties: [
        {
          startValue: 0,
          endValue: 186,
          property: 'translateY',
        },
      ],
    },
  ],
}

class HomepageOverlay extends React.Component {
  render() {
    const phone = this.props.globalContext.locale === 'es' ? phoneEs : phoneEn
    return (
      <div className={styles.homepageOverlay}>
        <div className={styles.homepageOverlay__bounds1}>
          <Plx
            parallaxData={plxData.butterfly1}
            className={classNames(
              styles.homepageOverlay__butterfly,
              styles.homepageOverlay__butterfly2
            )}
          >
            <Image src={butterfly1} alt="" width={60} />
          </Plx>
          <Plx
            parallaxData={plxData.butterfly2}
            className={classNames(
              styles.homepageOverlay__butterfly,
              styles.homepageOverlay__butterfly1
            )}
          >
            <Image src={butterfly2} alt="" width={50} />
          </Plx>
          <Plx
            parallaxData={plxData.butterfly3}
            className={classNames(
              styles.homepageOverlay__butterfly,
              styles.homepageOverlay__butterfly3
            )}
          >
            <Image src={butterfly3} alt="" width={80} />
          </Plx>
          <div className={styles.homepageOverlay__notes}>
            <HomepageAnimation
              className={styles.homepageNotesAnimation}
              options={{
                loop: true,
                autoplay: true,
                prerender: true,
                animationData: notesAnimationData,
              }}
              ref={el => {
                this.animation = el
              }}
            />
          </div>
        </div>

        {!this.props.hidePhone && (
          <div className={styles.homepageOverlay__bounds2}>
            <img src={clouds} className={styles.homepageOverlay__clouds} alt="clouds" />

            <Plx parallaxData={plxData.phone} className={styles.homepageOverlay__phone}>
              <Image
                src={phone}
                width="500"
                alt="Picture of the Vroom App on a smart phone"
                priority
              />
            </Plx>
          </div>
        )}
      </div>
    )
  }
}

export default withGlobalContext(HomepageOverlay)
