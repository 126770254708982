//Adapted from: https://github.com/Olical/react-bodymovin/blob/master/src/ReactBodymovin.js
import React from 'react'

import { captureMessage } from '@util/sentry'

class HomepageAnimation extends React.Component {
  componentDidMount() {
    const options = Object.assign({}, this.props.options)
    options.wrapper = this.wrapper
    options.renderer = 'svg'

    if (typeof window !== 'undefined' && this.wrapper) {
      import('lottie-web/build/player/lottie_light').then(lottie => {
        this.animation = lottie.loadAnimation({
          ...options,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        })
        this.animation.setSpeed(1)
      })
    }
  }

  componentWillUnmount() {
    try {
      this.animation && this.animation.destroy()
    } catch (e) {
      captureMessage('failed to destroy animation. Assuming it never mounted properly')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  render() {
    const storeWrapper = el => {
      this.wrapper = el
    }

    return (
      <div
        className={'homepage-animation react-bodymovin-container ' + this.props.className}
        ref={storeWrapper}
      />
    )
  }
}

export default HomepageAnimation
