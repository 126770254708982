import React from 'react'
import Image from 'next/image'

const ImageAsset = ({ asset, width, height, fill, ...rest }) => {
  if (!asset) return null
  if (!asset.fields) return null
  if (!asset.fields.file) return null

  let src = `${asset.fields.file.url}`
  if (src.startsWith('//')) src = 'https:' + src

  const assetWidth = asset.fields.file.details?.image?.width
  const assetHeight = asset.fields.file.details?.image?.height

  let sizeProps

  if (fill) {
    sizeProps = { fill }
  } else if (width && height) {
    sizeProps = { width, height }
  } else if (width && assetWidth) {
    sizeProps = { width, height: (width * assetHeight) / assetWidth }
  } else if (height && assetHeight) {
    sizeProps = { width: (height * assetWidth) / assetHeight, height }
  } else {
    sizeProps = { width: assetWidth, height: assetHeight }
  }

  return <Image src={src} alt={asset.description ?? ''} {...sizeProps} {...rest} />
}

export default ImageAsset
