import * as Sentry from '@sentry/browser'

const useSentry = process.env.NODE_ENV === 'production'

if (useSentry) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
  })
}

export const captureException = error => {
  if (useSentry) {
    captureException(error)
  }
}

export const captureMessage = message => {
  if (useSentry) {
    captureMessage(message)
  }
}

export default Sentry
