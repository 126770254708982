import React from 'react'
import Image from 'next/image'
import classNames from 'classnames'

import HomepageOverlay from '@components/blocks/homepage_overlay'
import Link from '@components/link'
import Markdown from '@components/markdown'
import styles from '@components/blocks/homepage_hero_block_mobile.module.scss'
import { withLazyRef } from '@util/lazy_ref'

import phoneEn from '/static/images/illustrations/phone_mobile.png'
import phoneEs from '/static/images/illustrations/phone_spanish_mobile.png'
import phoneBg from '/static/images/illustrations/phone_bg_wide.svg'
import ImageAsset from '@components/image_asset'

class HomepageHeroBlockMobile extends React.Component {
  getPageBackgroundColor = () => {
    return 'white'
  }

  render() {
    const { locale, fields } = this.props

    const phone = locale === 'es' ? phoneEs : phoneEn

    return (
      <div className={styles.hhbm}>
        <div className={styles.hhbm__primaryBanner}>
          <ImageAsset
            asset={fields.bannerImage}
            priority
            alt=""
            height={235}
            className={styles.hhbm__primaryBannerImage}
          />

          <div className={styles.hhbm__headerText}>{fields.header}</div>
          <HomepageOverlay hidePhone />
        </div>
        <div className={styles.hhbm__subheader}>{fields.subheader}</div>
        <Link path={fields.ctaPath} className={styles.hhbm__cta} data-link-type="homepage-hero">
          {fields.ctaText}
        </Link>
        <br />
        {fields.ctaText2 && (
          <Link
            path={fields.ctaPath2}
            className={classNames(styles.hhbm__cta, styles.hhbm__cta2)}
            data-link-type="homepage-hero"
          >
            {fields.ctaText2}
          </Link>
        )}
        <div className={styles.hhbm_body}>
          <Markdown className={styles.homepageHeroBlockMobile__body}>{fields.body}</Markdown>
        </div>
        <div className={styles.hhbm__secondaryHeader}>{fields.secondaryHeader}</div>
        <div className={styles.hhbm__appImage}>
          <Image className={styles.hhbm__appImage__bg} src={phoneBg} width="600" alt="" />
          <Image
            className={styles.hhbm__appImage__phone}
            src={phone}
            priority
            alt="Picture of the Vroom App on a smart phone"
          />
        </div>
        <Markdown className={styles.hhbm__secondaryBody}>{fields.secondaryBody}</Markdown>
        <div className={styles.hhbm__secondaryLinkIcon} onClick={this.props.openModal}>
          <Link className={styles.homepageHeroBlockMobile__secondaryLinkText}>
            {fields.secondaryLinkText}
          </Link>
        </div>
      </div>
    )
  }
}

export default withLazyRef(HomepageHeroBlockMobile)
