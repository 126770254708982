import React from 'react'

import classNames from 'classnames'

import HomepageHeroBlockMobile from '@components/blocks/homepage_hero_block_mobile'
import HomepageOverlay from '@components/blocks/homepage_overlay'
import RenderLargeUp from '@components/responsive/render_large_up'
import RenderSmallDown from '@components/responsive/render_small_down'
import Link from '@components/link'
import Markdown from '@components/markdown'
import styles from '@components/blocks/homepage_hero_block.module.scss'
import ImageAsset from '@components/image_asset'
import { withLazyRef } from '@util/lazy_ref'

class HomepageHeroBlock extends React.Component {
  state = {
    modalOpen: false,
  }

  openModal = () => this.setState({ modalOpen: true })
  closeModal = () => this.setState({ modalOpen: false })

  getPageBackgroundColor = () => {
    return 'white'
  }

  render() {
    const { fields } = this.props

    return (
      <div className={styles.homepageHeroBlock}>
        <RenderLargeUp>
          <div className={styles.homepageHeroBlock__primaryContent}>
            <ImageAsset
              asset={fields.bannerImage}
              priority
              alt=""
              class={styles.homepageHeroBlock__primaryContentBackgroundImage}
            />

            <div className={styles.homepageHeroBlock__wrapper}>
              <div className={styles.homepageHeroBlock__headerText}>
                <h1 className={styles.homepageHeroBlock__header}>{fields.header}</h1>
                <h2 className={styles.homepageHeroBlock__subheader}>{fields.subheader}</h2>
                <Link
                  path={fields.ctaPath}
                  className={styles.homepageHeroBlock__cta}
                  data-link-type="homepage-hero"
                  style={{ minWidth: 200 }}
                >
                  {fields.ctaText}
                </Link>
                {fields.ctaText2 && (
                  <Link
                    path={fields.ctaPath2}
                    className={classNames(
                      styles.homepageHeroBlock__cta,
                      styles.homepageHeroBlock__cta2
                    )}
                    data-link-type="homepage-hero"
                  >
                    {fields.ctaText2}
                  </Link>
                )}
                {fields.body && (
                  <Markdown className={styles.homepageHeroBlock__body}>{fields.body}</Markdown>
                )}
              </div>
            </div>
          </div>
          <div className={styles.homepageHeroBlock__secondaryContent}>
            <div className={styles.homepageHeroBlock__secondaryContent__text}>
              <div className={styles.homepageHeroBlock__secondaryHeader}>
                {fields.secondaryHeader}
              </div>
              {fields.secondaryBody && (
                <Markdown className={styles.homepageHeroBlock__secondaryBody}>
                  {fields.secondaryBody}
                </Markdown>
              )}
              <Link path={fields.secondaryLinkPath} className={styles.homepageHeroBlock__link}>
                <span className={styles.homepageHeroBlock__secondaryLinkText}>
                  {fields.secondaryLinkText}
                </span>
              </Link>
            </div>
            <HomepageOverlay {...this.props} />
          </div>
        </RenderLargeUp>
        <RenderSmallDown>
          <HomepageHeroBlockMobile {...this.props} openModal={this.openModal} />
        </RenderSmallDown>
      </div>
    )
  }
}

export default withLazyRef(HomepageHeroBlock)
