import React from 'react'
import { useMediaQuery } from 'react-responsive'

export const largeMinWidth = 768

// Workaround to support SSR and static site generation
// https://github.com/yocontra/react-responsive/issues/312#issuecomment-1627678155
export const useIsLargeUp = () => {
  const isLarge = useMediaQuery({ minWidth: largeMinWidth })

  const [isLargeUp, setIsLargeUp] = React.useState(true)

  React.useEffect(() => {
    setIsLargeUp(isLarge)
  }, [isLarge])

  return isLargeUp
}

const RenderLargeUp = ({ children }) => {
  const isLargeUp = useIsLargeUp()

  return isLargeUp ? children : null
}

export default RenderLargeUp
